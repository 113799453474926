import React, { useState, useEffect, useRef } from "react";
import "./DietForm.css";
import myClnqLogo from "../assets/images/logo.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import invitationDiet from "../assets/images/invitation.png";
import config from "../Utils/config";
import axios from "axios";
import ckhk from "../assets/images/logoNew.png";
import { useNavigate, useLocation } from "react-router-dom";
import SelectDropdown from "../components/SelectDropDown";

const DietInvitationForm = () => {
  const dietitianOptionsDefault = [
    // { value: "https://api.calendly.com/users/7fbf5121-a46c-479a-b35e-9e4bf248b02c|codingbytes1@gmail.com", label: "Dr Mani" },
  ];
  const [email, setEmail] = useState("");
  const [selectedDietitian, setSelectedDietitian] = useState({ value: "", label: "" });
  const [dietitianOptions, setDietitianOptions] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams.get("id");
  const isFirstRender = useRef(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && selectedDietitian && selectedDietitian?.value) {
      sendBookingLink();
    }
  };

  useEffect(() => {
    if (email && selectedDietitian && selectedDietitian?.value) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [email, selectedDietitian]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      const fetchData = async () => {
        try {
          const currentDate = new Date();
          const nextDate = new Date(currentDate);
          nextDate.setDate(currentDate?.getDate() + 1);
          const formattedNextDate = nextDate?.toISOString()?.split("T")[0];
          const response = await fetch(`${config?.baseURL}api/v1/dietConsultation/getDietitian?date=${formattedNextDate}`, {
            headers: {
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              'Pragma': 'no-cache',
              'Expires': '0'
            }
          });
          const result = await response.json();
          if (result && result?.data && result?.data?.availableDietitians && result?.data?.availableDietitians?.length > 0) {
            const formattedDietitians = result?.data?.availableDietitians?.map(dietitian => ({
              key: dietitian?.email,
              label: dietitian?.dietitianName,
              value: `${dietitian?.dietitianHostLink}|${dietitian?.email}`
            }));
            setDietitianOptions(formattedDietitians);
          }

          const fetchEmailResponse = await fetch(`${config?.baseURL}api/v1/dietChart/getOne/${id}`, {
            headers: {
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              'Pragma': 'no-cache',
              'Expires': '0'
            }
          });

          const resultEmail = await fetchEmailResponse.json();
          if (resultEmail && resultEmail?.data && resultEmail?.data?.purchaseDetail && resultEmail?.data?.purchaseDetail?.email) {
            setEmail(resultEmail?.data?.purchaseDetail?.email);
          }
        } catch (error) {
          if (error?.response && error?.response?.data && error?.response?.data?.message) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error("An unexpected error occurred");
          }
          navigate("/");
        }
      };
      fetchData();
    }
  }, []);

  const sendBookingLink = async () => {
    setButtonDisabled(false);
    try {
      let data = { email: email, dietitianData: selectedDietitian?.value };
      const response = await axios.post(`${config?.baseURL}api/v1/dietConsultation/sendBookingLink`, data);
      if (response.data.status === "success") {
        function extractCalendlyCode(url) {
          const regex = /https:\/\/calendly\.com\/d\/([a-zA-Z0-9-]+)/;
          const match = url?.match(regex);
          return match ? match[1] : null;
        }
        const code = extractCalendlyCode(response?.data?.schedulingLink);
        const presentDate = new Date();
        const nextDate = new Date(presentDate);
        nextDate.setDate(presentDate?.getDate() + 1);
        const formattedDate = nextDate?.toISOString()?.split("T")[0];
        const monthDate = formattedDate?.slice(0, 7);
        navigate(`/booking-time?monthDate=${monthDate}&date=${formattedDate}&code=${code}`);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setButtonDisabled(true);
    }
  };

  return (
    <div className="diet-invitation-form-container">
      <div className="section1">
        <img src={myClnqLogo} className="logo" alt="Logo" />
        <img src={invitationDiet} className="diet-invitation-img" alt="Comment" />
      </div>
      <div className="form-container">
        <div className="img-ckhk">
          <img src={ckhk} className="ckhk-image" alt="CKHK" />
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex-display-invitation-form">
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                style={{ borderTop: "none", borderRight: "none", borderLeft: "none" }}
                value={email}
                placeholder="Email"
                disabled={true}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="chooseDietitian">Dietitian:</label>
              <SelectDropdown
                options={dietitianOptions}
                selectedValue={selectedDietitian}
                onChange={(option) => setSelectedDietitian({ label: option?.label, value: option?.value })}
                placeholder="Select Dietitian"
                backgroundColor= "white"
                textColor="black"
                extraIcon={false}
                disabled={dietitianOptions?.length == 0}
                big={false}
                noMargin={true}
              />
            </div>
          </div>
          <br />
          <br />
            <button
            type="submit"
            className="btn-diet-plan"
            style={{
              cursor: buttonDisabled ? "pointer" : "not-allowed",
              backgroundColor: buttonDisabled ? "#ff4848" : "GrayText"
            }}
          >
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default DietInvitationForm;



{/* <select
                  id="chooseDietitian"
                  name="chooseDietitian"
                  className="form-control"
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140"><polyline points="10,50 70,110 130,50" fill="none" stroke="%23000" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat right center`,
                    backgroundColor: "white",
                    backgroundSize: "12px",
                    backgroundPosition: "right 12px center",
                  }}
                  value={selectedDietitian}
                  onChange={(e) => setSelectedDietitian(e.target.value)}
                  placeholder="Select Dietitian"
                  disabled={dietitianOptions.length === 0}
                  required
                >
                  <option value="" disabled hidden>Select Dietitian</option>
                  {(dietitianOptions.length > 0 ? dietitianOptions : dietitianOptionsDefault).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select> */}