import React from "react";
import DietInvitationForm from "./dietInvitationForm";

const Home = () => {

  return (
    <>
      <div className="page-content bg-white main-diet-section">
        <div className="main-bnr-one" id="home">
          <DietInvitationForm />  
        </div>
      </div>
    </>
  );
};

export default Home;
