
import React from "react";
import "./DietForm.css";
import "./BookingTimePage.css";
import "react-toastify/dist/ReactToastify.css";
import ckhk from "../assets/images/logoNew.png";
import { useNavigate, useLocation } from "react-router-dom";

const BookingTimePage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const monthDate = queryParams.get("monthDate");
  const date = queryParams.get("date");
  const code = queryParams.get("code");
  const navigate = useNavigate();
  let link = `https://calendly.com/d/${code}/dietitian-consultation?month=${monthDate}&date=${date}`;

  const navigateBack = () => {
    navigate("/");
  }

  return (
    <div className="booking-time-calendly-background">
      <div className="inner-div">
        <div className="img-ckhk-booking-page">
          <img src={ckhk} className="ckhk-image" alt="CKHK" />
        </div>
        <div className="calendlyComponent">
          <iframe
            className="customChanges"
            src={link}
            style={{ width: '100%', height: '120vh', border: 'none', borderRadius: "2px" }}
            allowFullScreen
            title="Dietitian Consultation Booking"
          >
          </iframe>
        </div>
        <div>
          <button type="submit" className="home-button" onClick={navigateBack}>
            Back To Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingTimePage;

