import React, { useState, useRef, useEffect } from "react";
import "./SelectDropdown.css";
import DownArrow from "../assets/images/downArrow.png";
// import { useTranslation } from "react-i18next";

const SelectDropdown = ({
    options,
    selectedValue,
    onChange,
    placeholder,
    backgroundColor,
    textColor,
    extraIcon,
    disabled,
    big,
    noMargin,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const modalRef = useRef(null);
    const optionRefs = useRef([]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleDropdownToggle = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
            if (!isOpen) {
                setFilteredOptions(options);
                setFocusedIndex(-1);
            }
        }
    };

    const handleOptionClick = (value) => {
        if (!disabled) {
            // onChange(value.value);
            onChange({ label: value?.label, value: value?.value, key: value?.key });
            setIsOpen(false);
            setSearchTerm("");
            setFilteredOptions(options);
        }
    };

    const handleKeyDown = (event) => {
        if (isOpen) {
            if (event.key === "ArrowDown") {
                event.preventDefault();
                setFocusedIndex((prev) => (prev + 1) % filteredOptions.length);
            } else if (event.key === "ArrowUp") {
                event.preventDefault();
                setFocusedIndex((prev) =>
                    prev === 0 ? filteredOptions.length - 1 : prev - 1
                );
            } else if (event.key === "Enter" && focusedIndex >= 0) {
                handleOptionClick(filteredOptions[focusedIndex]);
            } else if (event.key === "Escape") {
                setIsOpen(false);
            } else if (/^[a-zA-Z0-9\s]$/.test(event.key)) {
                const newSearchTerm = searchTerm + event.key;
                setSearchTerm(newSearchTerm);
                filterOptions(newSearchTerm);
            } else if (event.key === "Backspace") {
                const newSearchTerm = searchTerm.slice(0, -1);
                setSearchTerm(newSearchTerm);
                filterOptions(newSearchTerm);
            }
        } else if (event.key === "Enter") {
            handleDropdownToggle();
        }
    };

    const filterOptions = (term) => {
        const filtered = options.filter((option) =>
            option.toLowerCase().startsWith(term.toLowerCase())
        );
        setFilteredOptions(filtered);
        setFocusedIndex(0);
    };

    const truncateText = (text) => {
        // if (text && text.length > 30 && big) {
        //     return text.slice(0, 50) + "...";
        // }
        if (text && text.length > 20 && !big) {
            return text.slice(0, 50) + "...";
        }
        return text;
    };

    useEffect(() => {
        if (focusedIndex >= 0 && optionRefs.current[focusedIndex]) {
            optionRefs.current[focusedIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [focusedIndex]);

    const dropdownHeaderStyle = {
        backgroundColor: backgroundColor || "var(--main-bg)",
        color: "black",
        cursor: disabled ? "not-allowed" : "pointer",
    };

    return (
        <div
            className={`sel-dropdown ${disabled ? "disabled" : ""} ${big ? "big" : ""} ${noMargin ? "no-top-margin" : ""}`}
            ref={modalRef}
            tabIndex={0}
            onKeyDown={handleKeyDown}
        >
            <div className="sel-dropdown-header" style={dropdownHeaderStyle} onClick={handleDropdownToggle} >
                {extraIcon && (
                    <img
                        src={extraIcon}
                        alt="Extra Icon"
                        className="sel-dropdown-extra-icon"
                    />
                )}
                <span className={`sel-dropdown-placeholder ${big ? "big" : ""}`}>
                    {truncateText(selectedValue.label) || placeholder}
                    {/* {selectedValue || placeholder} */}
                </span>
                <span className={`sel-dropdown-arrow ${isOpen ? "open" : ""} ${big ? "big" : ""}`}>
                    <img src={DownArrow} alt="Arrow Icon" />
                </span>
            </div>
            {isOpen && (
                <ul className="sel-dropdown-list">
                    {filteredOptions.length === 0 ? (
                        <li className="sel-dropdown-item">{"No Record Present"}</li>
                    ) : (
                        filteredOptions.map((option, index) => (
                            <li
                                key={option?.email}
                                className={`sel-dropdown-item ${option === selectedValue.label ? "selected" : ""} ${index === focusedIndex ? "focused" : ""}`}
                                onClick={() => handleOptionClick(option)}
                                ref={(el) => (optionRefs.current[index] = el)}
                                style={{
                                    color: textColor,
                                    cursor: disabled ? "not-allowed" : "pointer",
                                    backgroundColor: "white",
                                }}
                            >
                                {/* {option.label} */}
                                {truncateText(option.label)}
                            </li>
                        ))
                    )}
                </ul>
            )}
        </div>
    );
};

export default SelectDropdown;
